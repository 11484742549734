import React from 'react';
import SEO from '../components/molecules/SEO';
import Container from '../components/atoms/Container';
import MarkdownConverter from '../components/atoms/MarkdownConverter';
import { graphql } from 'gatsby';
import styled from 'styled-components';

const Holder = styled.div`
  max-width: 50rem;
`;

const Text = styled.div`
  margin-bottom: 4rem;
  a { text-decoration: underline; }
`;

function Info( { data } ) {
  return (
    <>
      <SEO title={data.contentfulStaticPage.title}/>
      <div className="content-holder">
        <Container>
          <Holder>
            <Text>
              <MarkdownConverter
                content={data.contentfulStaticPage.pageText.pageText}/>
            </Text>
          </Holder>
        </Container>
      </div>
    </>
  )
}

export default Info;

export const pageQuery = graphql`
    query {
        contentfulStaticPage(title: {eq: "Info"}) {
            title
            pageText {
                pageText
            }
        }
    }
`;
